<template>
<div class="cate-div">
  <Else></Else>
  <div class="content-box" v-loading="loading">
    <div class="brand-list-title" style="border-bottom:1px solid #D8D8D8;display:flex;justify-content: space-between;">
      <div style="display:inline-block;border-bottom:1px solid #323232;font-size:18px;">申请中商标</div>
    </div>
    <div>
      <span style="margin-right:20px;">商标名称</span>
      <el-input v-model.trim="brand" size="mini" style="width:240px;margin-right:30px;font-size:14px"
                placeholder="请输入商标名称"></el-input>
                  
                 <span style="margin-right:20px;">商标状态</span>
                      <el-select
                    v-model="apllystatus"
                    style="width:165px;margin-right:30px;"
                     @change="change"
                    >
                    <el-option
                        value="全部">
                    </el-option>
                    <el-option
                        v-for="item in applyArr"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                  </el-select>
                      <el-button class="main-btn" size="mini" @click="search()">查询</el-button>
                      <el-button size="mini" @click="reset" class="reset-btn">重置</el-button>
                    </div>
          <el-row
            style="height: 42px;background: #F8F8F8;border: 1px solid #EEEEEE; display:flex;align-items: center;padding-left:20px;margin:30px 0 30px 0;">
            <span>
              商标申请中总数{{ brandAllNumber }}个
            </span>
          </el-row>
        <div>
          <div class="list-title">
             <div>商标名称</div>
             <div>商标图片</div>
             <div>申请人</div>
             <div>类别</div>
             <div>商标状态</div>
             <div class="title-ico">
                <div>申请日期</div>
                <div class="ico-div">
                  <i class="el-icon-caret-top" @click="up('0')"></i>
                  <i class="el-icon-caret-bottom" @click="down('1')"></i>
                </div>
             </div>
             <div>管理</div>
          </div>
          <div v-if="list.length>0">
              <div class="list-detail" v-for="(v,k) in list" :key="k">
                  <div>{{v.brandName}}</div>
                  <div style="line-height:normal;">
                    <img :src="v.brandImg" alt="">
                  </div>
                  <div style="line-height:normal;"><span>{{v.TMApplication}}</span></div>
                  <div style="width:150px;padding-left:15px">{{v.intCls}}</div>
                  <div style="color:#FE8526;">{{v.TMStatus}}</div>
                  <div>{{v.appDate}}</div>
                  <div @click="handleDetail(v.intCls,v.regNumber,v.TMApplication)" style="color:#63AAFF;cursor: pointer;">查看详情</div>
              </div>
          </div>
          <div v-else class="nodata">暂无数据</div>
      <div style="text-align: center;margin-top:20px;">
        <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="totalNumber"
                    :page-size="pageSize"
                    :current-page="current"
                    @current-change="currentChange">
            </el-pagination>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import {getAuthInfos,getstatus,getapplyall} from "../../api/api";
import Else from '../ui/else.vue';
export default {
  name: "brandList",
  data() {
    return {
      loading:false,
      brand: '',
      brandAllNumber: 0,
      current:1,
      apllystatus:'全部',
      applyArr:[],
      totalNumber :0,
      pageSize: 5,
      list:[],
      pai:"1",
      allcompany:[]
    }
  },
  filters:{
    
  },
  components: {
    Else
  },
  mounted() {
    this.init();
    // this.getAuthInfos()
    window.parent.postMessage(
          {
            data: 927,
          },
          "*"
        );
  },
  computed: {
    companyName() {
      return this.$route.query.company
    }
  },
  watch:{
    current(){
      this.getData();
    }
  },
  methods: {
    async init() {
      this.loading = true;
        let comList = await this.getAuthInfos() || [];
        await this.getstatus(comList);
        await this.getall(comList);
        await this.getData();
        //子向父发送数据
      this.$nextTick(()=>{
        var height =  document.documentElement.scrollHeight;
        console.log(height)
          window.parent.postMessage(
          {
            data: height,
          },
          "*"
        );
      })
      this.loading = false;
    },
     async getAuthInfos() { //获取认证公司名列表
      let res = await getAuthInfos({
        userBusinessCode:this.companyName
      })
      if (res.data.code === 200) {
        let comList = []
         comList = res.data.data.map(i => i.businessName);
        if(comList.indexOf(this.companyName) == -1){
          comList.push(this.companyName)
        }
        this.allcompany= comList
        return comList || []
      }
    },
    handleDetail(val1,val2,val3) {
      this.$router.push({
        path:'/brandDetail',
        query:{
          category:val1,
          regNum:val2,
          companyName:val3,
        }
      })
    },
    reset() {
      this.apllystatus = '全部';
      this.brand = '';
      this.pai = "1";
      this.current = 1;
      this.getData();
    },
    search(){
      this.getData();
    },
    up(){
      this.pai = "0"
      this.current = 1;
      this.getData()
    },
    down(){
      this.pai = "1";
      this.current = 1;
      this.getData()
    },
    async getData() {
      this.loading=true;
      let m = this.apllystatus === '全部'?'':this.apllystatus;
      if(this.allcompany.length>0){
        let res = await getapplyall({
        tmApplicant:this.allcompany,
        brandName:this.brand,
        brandType:m,
        page:this.current,
        timeOrderBy:this.pai,
        size:this. pageSize
        })
      if (res.data.code === 200) {
        this.list= res.data.data;
        this.totalNumber  = res.data.total;
        
      }
      this.loading=false;
      }
    },
    currentChange(e){
            this.current = e;
          },
    async getstatus(comList){
      let res = await getstatus({
        tmApplicant:comList,
         statusInApplication:0
        })
      if (res.data.code === 200) {
        // console.log(res)
        this.applyArr = res.data.data 
      }
    },
     change(){
      this.current = 1;
      this.getData()
    },
     async getall(comList){
              let res = await getapplyall({
                tmApplicant:this.allcompany,
                brandName:'',
                brandType:'',
                page:1,
                timeOrderBy:0,
                size:5
              })
              if(res.data.code === 200){
                this.brandAllNumber  = res.data.total;
              }
          },
  }
}
</script>

<style scoped>
.brand-list-title {
  font-weight: 500;
  color: #353535;
  line-height: 25px;
  margin-bottom: 30px;
}

.content-box {
    width: 1200px;
    margin:0 auto;
  min-height:calc(100vh - 304px);
  background: #FFFFFF;
  margin-top: 20px;
  box-shadow: 0px 0px 10px 0px rgba(85,84,91,0.2700);
  padding: 40px;
}
.main-btn {
  background: #2554B3;
  border-radius: 4px;
  color:#fff;
  border:none;
}
.reset-btn{
  background: #E9F0FF;
  border-radius: 4px;
  border: 1px solid #2554B3;
  color:#2554B3;
}
 :deep(.el-input__inner) {
    height: 28px !important;
  }
   :deep(.el-input__icon) {
    line-height: normal;
  }
   :deep(.el-pagination.is-background .el-pager li:not(.disabled).active){
    background: #2554B3;
   }
   .list-title{
    height: 47px;
    background: #F8F8F8;
    border: 1px solid #ECEAEA;
   }
   .list-title>div{
    float: left;
    height: 47px;
    line-height: 47px;
    width: 161px;
    padding-left: 10px;
   }
   .title-ico{
    height: 47px;
   }
   .title-ico>div{
    float: left;
   }
   .ico-div{
    position: relative;
   }
  .el-icon-caret-top{
    position: absolute;
    top:8px;
    left: 5px;
    cursor: pointer;
  }
  .el-icon-caret-bottom{
      position: absolute;
   top:20px;
    left: 5px;
    cursor: pointer;
  }
  .list-detail{
    height: 80px;
    border-bottom: 1px solid #F6F7FB;
  }
  .list-detail>div{
    float: left;
    height: 80px;
    width: 161px;
    line-height: 80px;
    padding-left: 10px;
    font-size: 14px;
    color: #86878A;
    overflow: hidden;
  }
  .list-detail>div>img{
    width: 58px;
    height: 48px;
    margin-top: 15px;
  }
  .list-detail>div>span{
    display: inline-block;
    margin-top: 15px;
  }
  .nodata{
    color: #86878A;
    height: 48px;
    line-height: 48px;
    border-bottom: 1px solid #F6F7FB;
    text-align: center;
  }
</style>
